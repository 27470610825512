<template>
  <div class="online">
    <!-- <videoList :teacherList="atteTeacherList" :stuList="atteStudentList" /> -->
    <div class="select">
      <div class="gradeList">
        <el-select
          v-model="className"
          placeholder="请选择"
          @change="changeClass"
          :disabled="!isTeacherAccount"
        >
          <el-option
            v-for="item in classList"
            :key="item.id"
            :label="
              (item.type == 2 ? '小班' : item.type == 1 ? '中班' : '大班') +
              item.name
            "
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="content">
      <div class="select-teacher">
        <p class="select-teacher-tit">选择教师</p>
        <div class="teacher-list">
          <div v-if="atteTeacherList.length">
            <div
              class="teacher"
              v-for="item in atteTeacherList"
              :key="item.id"
              @click="checkPerson(item)"
            >
              <p class="teacher-img" :class="item.isCheck ? '' : 'unchecked'">
                <img
                  v-if="item.isCheck"
                  src="../../../assets/img/online/Icon_Tea_C.png"
                  alt=""
                />
                <img
                  v-else
                  src="../../../assets/img/online/icon_teacher.png"
                  alt=""
                />
              </p>
              <p class="teacher-name" :title="item.name">{{ item.name }}</p>
            </div>
          </div>
          <div v-else>
            <div class="teacher">
              <p class="teacher-img" style="background: none">
                <img
                  src="../../../assets/img/school/Icon_Teacher_Ks.png"
                  alt=""
                />
              </p>
              <p class="teacher-name">暂无教师</p>
            </div>
          </div>
        </div>
      </div>
      <div class="select-stu">
        <p class="select-stu-tit">选择幼儿</p>
        <div class="stu-list">
          <div v-if="atteStudentList.length">
            <div
              class="stu"
              v-for="item in atteStudentList"
              :key="item.id"
              @click="checkPerson(item)"
            >
              <p class="stu-img" :class="item.isCheck ? '' : 'unchecked'">
                <img
                  v-if="item.isCheck"
                  src="../../../assets/img/online/Icon_Stu_C_b.png"
                  alt=""
                />
                <img
                  v-else
                  src="../../../assets/img/online/icon_stu.png"
                  alt=""
                />
              </p>
              <p class="stu-name" :title="item.name">{{ item.name }}</p>
            </div>
          </div>
          <div v-else>
            <div class="stu">
              <p class="stu-img" style="background: none">
                <img src="../../../assets/img/school/Icon_Stud_Ks.png" alt="" />
              </p>
              <p class="stu-name">暂无幼儿</p>
            </div>
          </div>
        </div>
      </div>
      <div class="select-class">
        <p class="select-class-tit">选择课程</p>
        <div class="class-list">
          <!-- selected 选中的状态 -->
          <div
            class="course"
            v-for="item in videoList"
            :key="item.id"
            @click="checkCourse(item)"
            :class="{
              selected: item.id === tabVideo.id,
              lock: item.publish === 0,
            }"
          >
            <div>
              <p
                class="course-grade"
                :class="
                  item.course.title === '中班课程'
                    ? 'mid-grade'
                    : item.course.title === '大班课程'
                    ? 'big-grade'
                    : ''
                "
              >
                {{
                  item.course.title === "中班课程"
                    ? "中"
                    : item.course.title === "小班课程"
                    ? "小"
                    : "大"
                }}班
              </p>
              <p class="course-section">第{{ item.lessons }}节</p>
              <p class="course-name">{{ item.videoname }}</p>
              <p class="course-looked" v-if="item.over"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-btn">
      <div class="goClass" @click="toClass">一键上课</div>
      <div class="goBack" @click="toBack">取消</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "adminOnlineclass",
  data() {
    return {
      isTeacherAccount: false, //是否为园长账号
      classList: [],
      className: Number(this.$route.query.className) || "", //班级名称
      atteStudentList: [],
      atteTeacherList: [],
      videoList: [],
      tabVideo: "", //选中视频
      curClassId: this.$route.query.gradeId || "", //当前班级id
    };
  },
  watch: {
    $route() {
      this.className = Number(this.$route.query.className);
    },
  },
  methods: {
    init() {
      if (!this.isTeacherAccount) {
        //园长账号
        this.schoolGetClassList();
      } else {
        this.getClassList();
      }
    },
    schoolGetClassList() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.nAdmin.findGradesBySchoolId(data).then((res) => {
        console.log(res, "dddddd");
        this.classList = res.data;
        this.className =
          (res.data[0].type == "2"
            ? "小班"
            : res.data[0].type == "1"
            ? "中班"
            : "大班") + res.data[0].name;
        console.log(this.className, "this.className");
        this.findClassGradeInfo(res.data[0]);
      });
    },
    //  老师账号获取上课班级
    getClassList() {
      console.log(this.api.newAsmin, "this.api.newAsmin");
      this.api.nAdmin
        .findGradesOfTid({
          teacherId: this.$store.state.userInfo.teacherId,
        })
        .then((res) => {
          this.classList = res.data;
          console.log(typeof Number(this.className), "this.className");
          if (!this.className) {
            // this.className = (res.data[0].type == "2" ? '小班' : res.data[0].type == "1" ? '中班': '大班') + res.data[0].name;
            this.className = res.data[0].id;
          }

          console.log(this.className, "this.className");
          if (!this.curClassId) {
            this.curClassId = res.data[0].id; //获取当前班级id
          }
          this.getList(res.data[0]);
        });
    },

    // 老师账号获取教师/学生/视频列表
    getList(res) {
      let data = {
        schoolId: res.schoolId,
        teacherId: this.$store.state.userInfo.teacherId,
        gradeId: res.id,
      };
      this.api.course.findEntryClass(data).then((res) => {
        console.log(res);
        this.atteStudentList = res.data.atteStudentList.map((item) => {
          return {
            ...item,
            isCheck: true,
          };
        });
        this.atteTeacherList = res.data.atteTeacherList.map((item) => {
          return {
            ...item,
            isCheck: true,
          };
        });
        this.videoList = res.data.videoList;
      });
    },
    // 园所账号获取教师/学生/视频列表
    findClassGradeInfo(res) {
      let data = {
        schoolId: res.schoolId,
        gradeId: res.id,
      };
      this.api.nAdmin.findClassGradeInfo(data).then((res) => {
        this.atteStudentList = res.data.atteStudentList.map((item) => {
          return {
            ...item,
            isCheck: true,
          };
        });
        this.atteTeacherList = res.data.atteTeacherList.map((item) => {
          return {
            ...item,
            isCheck: true,
          };
        });
        this.videoList = res.data.videoList;
      });
    },
    //切换班级
    changeClass(val) {
      this.tabVideo = "";
      this.curClassId = val;
      let curClass = this.classList.filter((item) => {
        return item.id === val;
      })[0];
      this.getList(curClass);
    },
    //选择老师/学生
    checkPerson(data) {
      data.isCheck = !data.isCheck;
    },
    //选择课程
    checkCourse(data) {
      if (data.publish != 0) {
        this.tabVideo = data;
      } else {
        this.$notice({
          // type: "success",
          message: "敬请期待",
        }).isShow();
        return;
      }
    },
    //一键上课
    toClass() {
      let stuList = [];
      let teacList = [];
      if (!this.isTeacherAccount) {
        this.$notice({
          message: "请使用教师账号一键上课",
        }).isShow();
        return;
      }

      if (!this.atteStudentList.length) {
        this.$notice({
          message: "请联系园长为该班级学员进行配课",
        }).isShow();
        return;
      }
      let stu = this.atteStudentList.filter((item) => {
        console.log(item);
        if (item.isCheck) {
          stuList.push(item.id);
        }
        return item.isCheck;
      });
      let teacher = this.atteTeacherList.filter((item) => {
        if (item.isCheck) {
          teacList.push(item.id);
        }
        return item.isCheck;
      });
      if (!teacher.length) {
        this.$notice({
          message: "请选择老师",
        }).isShow();
        return;
      }
      if (!stu.length) {
        this.$notice({
          message: "请选择学生",
        }).isShow();
        return;
      }
      if (!this.tabVideo.id) {
        this.$notice({
          message: "请选择课程",
        }).isShow();
        return;
      }
      this.findCourseConditions(this.tabVideo, stuList, teacList);
    },
    // 判断是否可看课
    findCourseConditions(item, stuList, TeachList) {
      let data = {
        gradeId: Number(this.curClassId),
        schoolId: Number(this.$store.state.userInfo.schoolId),
      };
      this.api.nAdmin.findCourseConditions(data).then((res) => {
        if (res.flag) {
          this.$router.push({
            path: "/onlineClass",
            query: {
              studentIds: stuList.join(","),
              teacherId: TeachList.join(","),
              unnormalUrl: this.tabVideo.unnormalUrl,
              courseId: this.tabVideo.id,
              gradeId: this.curClassId,
              videoId: item.id,
              videoTitle: `第${item.lessons}节:${item.videoname}`,
              gradeType: item.course.title,
            },
          });
        } else {
          this.$notice({
            // type: "success",
            message: res.message,
          }).isShow();
        }
      });
    },
    //返回
    toBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.isTeacherAccount = !!this.$store.state.userInfo.teacherId;
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.online {
  margin: 0 auto;
  width: 1354px;
  // width: 100%;
  padding: 15px 40px;
  background: #ffffff;
  border-radius: 2px;
  font-size: 18px;
  color: #333333;

  .select {
    margin: 16px auto 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .gradeList {
      width: 130px;
      height: 39px;
      font-weight: 400;
    }
  }
  .content {
    margin: 18px auto 0 auto;
    padding-bottom: 28px;
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 2px;

    .select-teacher-tit,
    .select-stu-tit,
    .select-class-tit {
      padding: 30px 0 15px 30px;
    }
    .teacher-list,
    .stu-list {
      margin-left: 30px;
      .teacher,
      .stu {
        display: inline-block;
        margin-right: 14px;
        margin-bottom: 14px;
        width: 100px;
        cursor: pointer;

        .teacher-img,
        .stu-img {
          margin: 0 auto;
          text-align: center;
          background: url("../../../assets/img/online/icon_choose.png")
            no-repeat 73px top;
          background-size: 16px 16px;
          > img {
            margin: 0 auto;
            width: 53px;
            height: 53px;
          }
        }
        .teacher-img.unchecked,
        .stu-img.unchecked {
          background: url("../../../assets/img/online/icon_UnC.png") no-repeat
            73px top;
          background-size: 16px 16px;
        }
        .teacher-name,
        .stu-name {
          margin-top: 5px;
          font-size: 14px;
          width: 100px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .class-list {
      padding: 0 15px 0 30px;
      @include flex(row, flex-start, top);
      flex-wrap: wrap;
    }
    .course {
      margin-right: 15px;
      margin-bottom: 22px;
      position: relative;
      display: inline-block;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(131, 131, 131, 0.24);
      border-radius: 4px;
      width: 154px;
      min-height: 130px;
      // overflow: hidden;
      cursor: pointer;

      .course-grade {
        padding: 4px 0 0 16px;
        position: absolute;
        top: 6px;
        right: -4px;
        background: url("../../../assets/img/online/Bq_class_Xb_White.png");
        background-size: cover;
        width: 41px;
        height: 26px;
        font-size: 14px;
        color: #ff676f;
      }
      .mid-grade {
        background: url("../../../assets/img/online/Bq_class_Zb_White.png");
        background-size: cover;
        color: #ffffff;
      }
      .big-grade {
        background: url("../../../assets/img/online/Bq_class_Db_White.png");
        background-size: cover;
        color: #ffffff;
      }
      .course-section {
        padding: 19px 0 0 16px;
        font-size: 24px;
      }
      .course-name {
        padding: 5px 16px 22px 16px;
      }
      .course-looked {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 28px;
        height: 20px;
        background: url("../../../assets/img/online/Icon_Class_W.png") no-repeat;
        background-size: cover;
      }
    }
    .course.lock {
      background: linear-gradient(to top right, #cbcaca, #929292);
      color: #ffffff;
      > div {
        width: 100%;
        height: 100%;
        background: url("../../../assets/img/online/Icon_class_Lock.png")
          no-repeat center center;
      }
    }
    .course.selected,
    .course:hover {
      background: linear-gradient(to top right, #ff9a9f, #ff5a62);
      color: #ffffff;
    }
  }
  .foot-btn {
    margin-top: 30px;
    margin-bottom: 25px;
    @include flex(row, flex-start, top);
    .goClass {
      margin-right: 30px;
      background-image: url("../../../assets/img/online/Btn_Yjsk.png");
      background-size: cover;
      width: 111px;
      height: 49px;
      text-align: center;
      line-height: 45px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      a {
        color: #fff;
      }
    }
    .goClass:hover {
      background-image: url("../../../assets/img/online/Btn_Yjsk_hg.png");
      background-size: cover;
    }
    .goBack {
      margin-top: 2px;
      width: 100px;
      height: 36px;
      border: 1px solid #ba3037;
      border-radius: 6px;
      text-align: center;
      line-height: 38px;
      color: #ba3037;
      font-size: 18px;
      cursor: pointer;
    }
    .goBack:hover {
      border: 1px solid #ee444e;
      color: #ee444e;
    }
  }
}
</style>